<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section
        class="image-cover hero-banner py-5"
        style="background: #eff6ff url(assets/img/banner-1.jpg) no-repeat"
      >
        <router-link
          :to="{ name: 'register' }"
          class="btn btn-primary agency-register-back"
          ><i class="lni lni-chevron-left me-2"></i>Back</router-link
        >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="checkout-wrap">
                <div class="checkout-head">
                  <div class="text-center mb-3">
                    <h3>Agency Registration</h3>
                  </div>
                  <ul>
                    <li class="active">
                      <span><i class="fa fa-dot-circle-o"></i></span>Account
                      Information
                    </li>
                    <li class="">
                      <span><i class="fa fa-dot-circle-o"></i></span>Agency
                      Details
                    </li>
                    <li class="">
                      <span><i class="fa fa-dot-circle-o"></i></span>Documents
                    </li>
                    <li class="">
                      <span><i class="fa fa-dot-circle-o"></i></span>Promotion
                    </li>
                  </ul>
                </div>
                <div class="checkout-body">
                  <div class="row justify-content-center">
                    <div class="col-md-10">
                      <div class="card border-0">
                        <div class="card-body">
                          <h4 class="card-header-title text-center mb-4">
                            Account Information
                          </h4>
                          <div class="login-form">
                            <form @submit.prevent="register">
                              <div class="row">
                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <div class="input-with-icon">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Full Name"
                                        required
                                        v-model="user.name"
                                      />
                                      <i class="ti-user"></i>
                                    </div>
                                    <span
                                      class="invalid-feedback d-block"
                                      v-if="errors.name"
                                      >{{ errors.name[0] }}</span
                                    >
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <div class="input-with-icon">
                                      <input
                                        type="email"
                                        class="form-control"
                                        placeholder="Email"
                                        required
                                        v-model="user.email"
                                      />
                                      <i class="ti-email"></i>
                                    </div>
                                    <span
                                      class="invalid-feedback d-block"
                                      v-if="errors.email"
                                      >{{ errors.email[0] }}</span
                                    >
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <div class="input-with-icon">
                                      <input
                                        type="password"
                                        class="form-control"
                                        placeholder="Password"
                                        required
                                        v-model="user.password"
                                      />
                                      <i class="ti-unlock"></i>
                                    </div>
                                    <span
                                      class="invalid-feedback d-block"
                                      v-if="errors.password"
                                      >{{ errors.password[0] }}</span
                                    >
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group">
                                    <div class="input-with-icon">
                                      <input
                                        type="password"
                                        class="form-control"
                                        placeholder="Confirm Password"
                                        required
                                        v-model="user.password_confirmation"
                                      />
                                      <i class="ti-unlock"></i>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                  <div class="form-group">
                                    <input
                                      type="checkbox"
                                      name="terms"
                                      required
                                      id="terms-and-conditions"
                                    />
                                    <label
                                      for="terms-and-conditions"
                                      class="ms-2"
                                      >By clicking the buttons below, I accept
                                      the
                                      <a
                                        :href="terms"
                                        target="_blank"
                                        class="text-warning"
                                        >Terms and conditions.</a
                                      ></label
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="form-group text-center">
                                <button
                                  type="submit"
                                  class="btn btn-md btn-theme-light-2 rounded"
                                  v-if="!is_loading"
                                >
                                  Proceed
                                </button>
                                <a
                                  href="#"
                                  @click.prevent=""
                                  v-if="is_loading"
                                  class="btn btn-md btn-theme-light-2 rounded"
                                  ><i class="lni lni-spinner"></i> Loading</a
                                >
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  beforeMount() {
    if(this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({ name: 'redirect' }).href
    }
  },

  mounted(){
    document.title = 'RealHub Kenya: Join the Leading Property Platform - Sign Up and Start Selling Today'

    let terms = this.$store.getters.getDocuments.filter(doc => {
      return doc.default
    })

    if(terms) {
      this.terms = terms[0].url
    }
  },

  data() {
    return {
      user: {},
      errors: {},
      is_loading: false,
      has_error: false,
      terms: ''
    };
  },

  methods: {
    register() {
      this.is_loading = true;
      this.has_error = false;
      let data = this.user
      data.referral_code = this.$route.query.referral_code

      this.$axios
        .post("/api/v1/register", data)
        .then((response) => {
          this.$auth.setToken(response.data.access_token);

          window.location.href = this.$router.resolve({ name: "add-agency" }).href;
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
          } else {
            this.errors = {};
          }

          this.is_loading = false;
        });
    },
  },
};
</script>
