<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section class="image-cover hero-banner py-5" style="background: #eff6ff url(assets/img/banner-1.jpg) no-repeat">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-7">
              <div class="card">
                <div class="row text-center g-0">
                  <div class="col-sm-6">
                    <div class="border-end p-2">
                      <a href="#" class="d-block text-primary" @click.prevent="">Sign Up as User</a>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="border-bottom p-2 bg-light">
                      <router-link :to="{ name: 'register-agency' }" class="d-block">Sign Up as Agency</router-link>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-header-title text-center mb-4">Sign Up</h3>
                  <div class="login-form">
                    <register @success="success" />
                  </div>
                  <!-- <div class="modal-divider" v-if="!is_loading"><span>Or login via</span></div>
                  <div class="social-login mb-3" v-if="! is_loading">
                    <social-auth 
                      @hasError="has_error = true" 
                      @noError="has_error = false"
                      @isLoading="is_loading = true" 
                      @doneLoading="is_loading = false" 
                    />
                  </div> -->
                  <div class="text-center" v-if="!is_loading">
                    <p class="mt-5">
                      <i class="ti-user mr-1"></i>Already Have An Account?
                      <router-link :to="{ name: 'login' }" class="link">Go To LogIn</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";
import Register from '@/components/auth/Register.vue'
// import SocialAuth from './components/Social.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
    Register,
    // SocialAuth
  },

  beforeMount() {
    if(this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({ name: 'redirect' }).href
    }
  },

  mounted(){
    document.title = 'RealHub Kenya: Join the Leading Property Platform - Sign Up and Find Your Dream Home Today'
  },

  methods: {
    success() {
      window.location.href = this.$router.resolve({ name: 'home' }).href
    }
  }
};
</script>
